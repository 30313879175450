@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400&display=swap');
@import 'react-toastify/dist/ReactToastify.min.css';

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.divTable {
    display: table;
    width: 100%;
}

.divTableRow {
    display: table-row;
}

.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
}

.divTableCell,
.divTableHead {
    /* border: 1px solid #999999; */
    display: table-cell;
    padding: 3px 10px;
}

.divTableHeading {
    background-color: #EEE;
    display: table-header-group;
    font-weight: bold;
}

.divTableFoot {
    background-color: #EEE;
    display: table-footer-group;
    font-weight: bold;
}

.divTableBody {
    display: table-row-group;
}

.cardStyle {
    padding: 5pt 5pt 5pt 5pt;
    border-radius: 1em;    
    border-color: lightgray;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.Button-Container {
    padding: 10pt 10pt 10pt 0pt;
}

.iconStyle {
    font-size: 200;
    margin: 0 25px;
    height: 50;
    width: 50;    
}